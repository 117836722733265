html,
body {
  scroll-behavior: smooth;
}

.BrainhubCarousel__dots .BrainhubCarousel__dot {
  background: transparent;
}

/* encode-sans-regular - latin */
@font-face {
  font-family: 'Encode Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('/fonts/encode-sans-v8-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('/fonts/encode-sans-v8-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/encode-sans-v8-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/encode-sans-v8-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/encode-sans-v8-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/encode-sans-v8-latin-regular.svg#EncodeSans')
      format('svg'); /* Legacy iOS */
}
/* encode-sans-600 - latin */
@font-face {
  font-family: 'Encode Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url('/fonts/encode-sans-v8-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''), url('/fonts/encode-sans-v8-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/encode-sans-v8-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/encode-sans-v8-latin-600.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/encode-sans-v8-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/encode-sans-v8-latin-600.svg#EncodeSans') format('svg'); /* Legacy iOS */
}
